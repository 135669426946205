import React from 'react';
import { Helmet } from "react-helmet";
import { dataDevelopers } from './../data/dataDevelopers';
import { Link } from 'react-router-dom';
import AnimHandshake from './../component/animHandshake';

const PageDevelopers = () => {

    return <div id="pageDevelopers" className="container">
        <Helmet>
            <title>beamLive Developers - Mobile IoT Communication as a Service</title>
            <meta name="description" content="beamLive - Mobile IoT “Communication as a Service” configures IoT data into relevant content and mobile messaging apps using sub-second M2M connectivity and AI." />
        </Helmet>

        <section className="row header">
            <div className="col-12 col-lg-6 position-relative order-last order-lg-first">
                <span className="blue-box"></span>
                <div className="text-box">
                    <h1><b>{dataDevelopers.title}</b></h1>
                    {dataDevelopers?.description?.map((line, lineIndex) => <p key={lineIndex}>{line}</p>)}
                </div>
            </div>
            <div className="col-12 col-lg-6">
                <AnimHandshake />
            </div>
        </section>


        <section className="row contact">
            <span className="red-box"></span>
            <div className="col-12 offset-lg-3 col-lg-7 text-box">
                {dataDevelopers.contact.map((line, lineIndex) => (
                    <p key={lineIndex}>{line}</p>
                ))}
                <div className="row mt-5">
                    <div className="col">
                        <Link className="col btn btn-beam w-100 btn-primary" to="/contact">Contact us</Link>
                    </div>
                    <div className="col">
                        <Link className="col btn btn-beam w-100 btn-outline-success btn-bg-white" to={{ pathname: "/contact", state: { isRequestLiveDemo: true } }}>Request live demo</Link>
                    </div>
                </div>
            </div>
        </section>
    </div>
}

export default PageDevelopers
