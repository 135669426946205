import React from 'react';
import { Helmet } from "react-helmet";

const PagePrivacy = () => (
    <div className="container">
        <Helmet>
            <title>beamLive Privacy Policy - Mobile IoT Communication as a Service</title>
            <meta name="description" content="beamLive - Mobile IoT “Communication as a Service” configures IoT data into relevant content and mobile messaging apps using sub-second M2M connectivity and AI." />
        </Helmet>

        <h1>Beam Live Inc. Privacy Policy</h1>
        <p>Beam Mobile IoT Communication Service is primarily an enterprise-grade cloud-based content sharing, messaging and collaborative tool that is customized for specific needs of an organization and its target users. In general Beam privacy policy addresses user’s 'Personally Identifiable Information' (PII) and other data as used in US privacy law and information security. However, each particular custom app or service can have its own privacy policy set by organization offering it. Beam privacy policy provides insight on What we collect and access? Who gets to see the information? And How beam protect your personal data?</p>
        <h2>What we collect and access?</h2>
        <p>When using our apps and/or visiting the Web version, we collect name, phone number, email address, mailing address, credit card information and access GPS, Microphone, Camera, Contact list and other IoT sensors and devices. Collected information through our mobile apps or website can be used to profile, identify, contact, record audio and video or locate a user in real-time.</p>
        <h2>Who gets to see the information?</h2>
        <p>Beam apps and services are used to communicate live and share text, media content and location with other users manually or dynamically. Data collection is enabled or authorized mainly by users and shared via a dynamic messaging and chat service with full user control. We personalize user's experience to deliver targeted content with user approval. Beam does not intend to use any personal information for commercial or marketing purposes or to share with 3rd parties unless we provide user with advance notice and get user approval. Emails and SMS messages are used only for verification and notification while using a specific app or service.</p>
        <h2>How beam protect your personal data?</h2>
        <p>Our apps and services are encrypted and regularly scanned for any vulnerabilities in order to protect our user’s private information. Users have sole control over collection and sharing of information with other users via an encrypted and Secure Socket Layer (SSL) technology. All personal data is stored on secured cloud-services or designated private servers. Data archiving policies can be set by administrator for specific applications and services. All credit card and payment transactions are processed through a 3rd party secured gateway provider and are not stored or processed on Beam servers. We use cookies through user web browsing experience to help us secure user data, remember user preferences and provide analytics for personal or enterprise use. Users can opt out and disable access to any mobile phone or browser sensor by simply disabling it at device or browser level. In the case that Beam is involved in a merger, acquisition or asset sale with other entities, we will provide notice before user personal information is transferred and becomes subject to a different Privacy Policy.</p>
        <p>You can contact us for any additional information or if you feel your personal account and data has been compromised by emailing us at <a href="mailto:support@beam.live?subject=For Privacy" target="_blank" rel="noreferrer">support@beam.live</a></p>
    </div>
)

export default PagePrivacy