const dataAbout = {
    header: {
        title: "About beam",
        description: [
            "beamLive, Inc., based in Silicon Valley and London, UK, developed and patented its Unified Communication of Things as-a-Service (UCTaaS), that can be customized to meet the specific needs of its clients.",
            "This system configures dynamic Internet of Things(IoT) data from various inputs(including video, GPS, home, vehicle and medical devices) into sub - second latency, mobile messages. It combines and merges that content with human intelligence to produce, relevant content, vital, life-saving alerts and Actionable Knowledge for a target audience."],
    },
    patent: {
        title: "Connected Intelligence",
        description: [
            "Beam's patented technology creates Analytics-in-Real-time around specific events (such as 911 incidents). It is powered by AI (on Cloud or Edge) and our unique M2M sub-second connectivity across any mobile IoT device or service. It profiles an Event by extracting relevant IoT content for a targeted audience from multiple, real-time information streams, including GPS, cameras and medical devices, and merges that with human intelligence. That unique process creates and instantly assigns Tasks to people or machines and delivers real-time mission-critical, time-sensitive, and Actionable Knowledge.",
            "Beam has helped customers optimize their response methodology by reducing decision-making and operational time from days to minutes. We currently support a variety of industries including Public Safety, Smart Transportation, Logistics and Delivery, Retail/Merchandising and Healthcare.",
            "(UCTaaS) On Cloud or Edge platform is developer-friendly, which enables a variety of products and services to be launched quickly and inexpensively on Windows, Apple, Android and Linux operating systems."
        ],
    },
    features: {
        title: "General beamPlatform Features",
        description: [
            "Designed for Mission-critical Communication and Time-sensitive Decision-making.",
            "Easy to use and provides smooth migration from legacy communication to broadband IoT.",
            "Turns IoT data from different sources such as GPS, Camera, Home, Vehicle, Wearable & Medical devices into Android, iOS & Wins apps.",
            "A developer-friendly cloud-based platform that enables a variety of products and services to be launched quickly and inexpensively.",
            "Augments human intelligence and mobile content with live IoT data and turn it into Actionable Knowledge and custom analytics.",
        ],
    },
    boxes: [
        { id: "admin", title: "beamAdmin", description: "An intuitive Web Portal to register and manage People & Assets and configure Apps" },
        { id: "live", title: "beamLive", description: "Configurable Mobile Messaging and Dynamic IoT Content Sharing on iOS, Android & Web" },
        { id: "analytics", title: "beamAnalytics", description: "Indexed and custom analytics creating actionable knowledge aimed at core products and profitability" }
    ],
    different: {
        title: "What makes Beam different",
        description: "Beam augments human intelligence with actionable knowledge extracted live from the Internet of Things (IoT) for mission-critical communication and time-sensitive decision-making. It is a Communication-as-a-Service “CaaS” cloud-based platform that is easily and inexpensively configurable.",
        items: [
            "Turns IoT Content into Instant Messages & Relevant Content with Beam’s sub-second M2M connectivity.",
            "Filters IoT Data into Actionable Knowledge using Beam’s advanced Artificial Intelligence (AI) protocols & algorithms.",
            "Configures IoT Data into Mobile Apps utilizing our semi-customizable cloud platform.",
        ],
    },
}

export default dataAbout