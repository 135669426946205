import React from 'react'
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.scss';
import '@fortawesome/fontawesome-free/js/all';
import PageLayout from './pages/pageLayout';
import PageHome from './pages/pageHome';
import PageIndustries from './pages/pageIndustries';
import PageProducts from './pages/pageProducts';
import PageProduct from './pages/pageProduct';
import PagePartners from './pages/pagePartners';
import PageDevelopers from './pages/pageDevelopers';
import PageAbout from './pages/pageAbout';
import PageContact from './pages/pageContact';
import PageTerms from './pages/pageTerms';
import PagePrivacy from './pages/pagePrivacy';
import Page404 from './pages/page404';

ReactDOM.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<PageLayout />}>
                {/* ============= Static Pages ============== */}
                <Route index element={<PageHome />} />
                <Route exact path="/industries" element={<PageIndustries />} />
                <Route exact path="/products" element={<PageProducts />} />
                <Route path="/products/:slug" element={<PageProduct />} />
                <Route exact path="/partners" element={<PagePartners />} />
                <Route exact path="/developers" element={<PageDevelopers />} />
                <Route exact path="about" element={<PageAbout />} />
                <Route exact path="/contact" element={<PageContact />} />
                {/* =============== Other Pages =============== */}
                <Route exact path="/privacy" element={<PagePrivacy />} />
                <Route exact path="/terms" element={<PageTerms />} />
                <Route path="*" element={<Page404 />} status={404} />
            </Route>
        </Routes>
    </BrowserRouter>
    , document.getElementById("root"));