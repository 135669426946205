const dataIndustrial = [
    {
        hash: "public-safety",
        title: "When Seconds Count",
        url: "https://first-admin.beam.live/",
        description: [
            "Beam's patented technology qualifies and shares emergency requests from IoT sensors (home, vehicle, medical) and smartphone apps with 911 PSAPs. Our “Intelligent Dispatching” utilizes machine learning to augment existing CAD and instantly geo-locate and communicate with resources and assets pertinent to this event. Our objective is to reduce request-to-response time from 3 to 5 minutes to less than 30 seconds.",
            "We help you to bring the power of IoT and analytics to Mobility and enable cost-effective and smooth migration from legacy, voice-centric communication to true broadband IoT technology.",
            "Beam can turn minutes into seconds for emergency response and First Responder safety. Beam is currently deployed with the Honolulu Police Department with our FirstNet Verified app – beamFirst. It provides mobile communication, content sharing and outcome analysis of mission critical operations. The beamFirst App can be found in AT&T’s FirstNet App catalog.",
        ]
    },
    {
        hash: "private-security",
        title: "Private Security",
        url: "https://first-admin.beam.live/",
        description: [
            "beam offers Private Security Service firms a best-in-class, unified communication solution that delivers the power of IoT and analytics to Mobility while enhancing cost-effective communication, location-based services, situational awareness and workflow management.",
            "Security companies cannot compromise on quality of service even with today’s constant budget and cost challenges. That’s why we offer a competitive, configured-to-your-needs solution which embraces a BYOD deployment and increases revenue as you cultivate a Communication as a Service (CaaS) pricing model.",
            "Today data privacy and custody are key elements. beam will never sell or re-market your data like some of the other BIG TECH service providers. Don’t force fit other services, like What’sApp or SKYPE, when you can thrive with beam - an easy-to-use service designed by first responders and security professionals for responders and security professionals.",
        ]
    },
    {
        hash: "communication",
        title: "Unified Communication",
        subtitle: "UCC Reimagined with IoT!",
        url: "https://ucc-admin.beam.live",
        description: [
            "We customize your preferred messaging, content sharing and collaborative features and add Mobility and IoT Communication from any source into the devices of your choice. Customized and targeted Analytics are provided around the core of your business to help your bottom line.",
        ]
    },
    {
        hash: "transportation",
        title: "Smart Transportation",
        subtitle: "Safe Rides. Vetted Drivers",
        url: "https://meter.beam.live",
        description: [
            "Let us show how you can eliminate your current operating costs and add new sources of high-margin revenue using beam’s broadband mobile technology and geo-analytics.",
            "Beam offers the only soft GPS meter on the market that is nationally certified by NTEP and NCWM. This end-to-end App integrates all meter functionality, dispatching and payment options plus safety for drivers and passengers. Our Effiliate” program is a value-added service for network of vetted drivers offering essential and peripheral services that normally burden transportation operators.",
        ]
    },
    {
        hash: "merchandising",
        title: "Retail Merchandising",
        subtitle: "Mobility & Analytics enhanced by IoT",
        url: "https://store-admin.beam.live",
        description: [
            "Augment human intelligence collected from mobile devices with live IoT data gathered from retail stores -  and turn that into real-time actionable Knowledge that saves money and operational time.",
            "For the first time, combined Mobility, Analytics and IoT capabilities are being applied to a retail environment, particularly for enhancing product-specific displays in real-time, and electronic inventory management."
        ]
    },
    {
        hash: "telehealth",
        title: "Telehealth",
        subtitle: "Virtual visit and real-time Alerts",
        url: "https://rx-admin.beam.live",
        description: [
            "We overlay live video and audio with medical sensor data using beam’s sub-second M2M connectivity to offer a true virtual office visit. Vital and life-saving alerts are continually generated using beam’s machine learning algorithm.",
            "We are partnering with several medical wearable manufacturers and health facilities to deliver a HIPPA-complaint user experience. Beam secure cloud using medical wearable and IoT technology is enabling new levels of mobile healthcare connectivity.",
        ]
    },
    {
        hash: "logistics",
        title: "Logistics & Delivery",
        subtitle: "Connect Customers to Deliveries",
        url: "https://logistics-admin.beam.live",
        description: [
            "We eliminate costs associated with multi-vendor services/portals by offering an end-to-end Mobile Cloud technology that covers your warehouse activities while linking end-customers to delivery on-the-go.",
            "Some of the biggest names in “Safe-Hand” delivery are replacing their services with our technology on the cloud. Beam can heighten mobile communication capabilities for delivery of valuables using its video and GPS technology as well as last-mile delivery systems. "
        ]
    }
];

export default dataIndustrial