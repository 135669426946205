import React from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import dataIndustrial from '../data/dataIndustrial';


const PageIndustries = () => {
    return (
        <div id="pageIndustries" className="container">
            <Helmet>
                <title>beamLive Industries - Mobile IoT Communication as a Service</title>
                <meta name="description" content="beamLive - Mobile IoT “Communication as a Service” is designed for Mission-critical Communication and Time-sensitive Decision-making." />
            </Helmet>

            <section className="row header">
                <span className="hero-image"></span>
                <h1>Public Safety & Emergency Response</h1>
                <div className="col-12 hero-box">
                    <span className="incident" />
                    {dataIndustrial.slice(0, 2).map((item, itemIndex) => (
                        <section key={itemIndex} id={item.hash} className="mb-4">
                            <h4 className="col-12"><b>{item.title}</b></h4>
                            {item.description.map((description, descriptionIndex) => (
                                <p className="col-12" key={descriptionIndex}>{description}</p>
                            ))}
                            <p><a href={item.url} target="_blank" rel="noreferrer">Read more</a></p>
                        </section>
                    ))}
                </div>
                <span className="red-box"></span>
            </section>

            <section className="row icons">
                <span className="pay" />
                <span className="exclamation" />
                <span className="place" />
                <span className="audio" />
            </section>

            {dataIndustrial.slice(2, 7).map((item, itemIndex) => (
                <section className="row booklet mb-5" id={item.hash} key={itemIndex}>
                    <span className="blue-box"></span>
                    <h2 className="pt-4">{item.title}</h2>
                    <div className="book-item col-12 p-0 mt-4 d-lg-flex justify-content-center">
                        <div style={{ order: (itemIndex % 2) }}>
                            <span className={`icon-box ${item.hash}`}></span>
                        </div>
                        <div><div className="text">
                            <h5 className="mb-3"><b>{item.subtitle}</b></h5>
                            {item.description.map((description, descriptionIndex) => (
                                <p key={descriptionIndex}>{description}</p>
                            ))}
                            <p><a href={item.url} target="_blank" rel="noreferrer">Read more</a></p>
                        </div></div>
                    </div>
                </section>
            ))}

            <section className="row buttons d-flex justify-content-center">
                <Link className="btn btn-beam btn-lg btn-primary" to="/contact">Contact us</Link>
                <Link className="btn btn-beam btn-lg btn-outline-success" to={{ pathname: "/contact", state: { isRequestLiveDemo: true } }}>Request live demo</Link>
            </section>
        </div>
    )
}

export default PageIndustries