export const dataDevelopers = {
    title: "Developers",
    description: [
        "Join beamESPP",
        "Entrepreneur Solutions Provider Program",
    ],
    contact: [
        "Developers and tech-savvy entrepreneurs are invited to participate in Beam’s Entrepreneur Solutions Provider Program to help build and expand the Beam brand through key market business development.",
        "Beam will provide training and sales materials for our Unified Communication of Things-as-a-Service for you to cultivate opportunities for partnerships and new customers to expand the Beam business base.",
        "We have already developed distinct products for key industry segments, as well as having identified the industries most in need of Beam’s breakthrough service offering. Our recurring-revenue model provides significant compensation for the duration of the relationship.",
        "If you can leverage your contacts and relationships to promote Beam growth we invite you to join us for our next ESSP introduction and training session.",
        "To register or for more information contact Bill Lane, Executive Vice President of Marketing, at b.lane@beam.live.",
    ]

}