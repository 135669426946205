import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { dataProducts } from './../data/dataProducts';
import { Fragment } from 'react/cjs/react.production.min';

const PageProducts = () => {
    let colorIndex = -1;

    return (
        <div id="pageProducts" className="container">
            <Helmet>
                <title>beamLive Products - Mobile IoT Communication as a Service</title>
                <meta name="description" content="beamLive - Mobile IoT “Caas” turns IoT data from different sources such as GPS, Camera, Home, Vehicle, Wearable & Medical devices into Android, iOS & Wins apps." />
            </Helmet>
            <section className="row header bg-white rounded rounded-8 p-4">
                <span className="blue-box"></span>
                <h1 className="col-12 fw-bolder my-3 ms-md-3"><b>Products</b></h1>
                {dataProducts.categories.map((category, categoryIndex) =>
                    <Fragment key={categoryIndex}>
                        <h3 className="col-12 fw-bolder mt-5 ms-md-3">{category.title}</h3>
                        {
                            category.products.map((product, productIndex) => {
                                const beamIndex = (++colorIndex % 7) + 1;
                                return <Fragment key={productIndex}>
                                    <div className="d-flex align-align-items-stretch col-6 col-md-4 text-white p-0 m-0" >
                                        <div className={"d-flex flex-column rounded rounded-5 m-2 m-md-4 px-4 bg-beam-" + beamIndex}>
                                            <div className="my-5">
                                                <div className={"d-inline p-4 bg-white p-4 rounded rounded-6 text-beam-" + beamIndex}>
                                                    {product.iconSVG && <span fill="transparent" dangerouslySetInnerHTML={{ __html: product.iconSVG }} />}
                                                    {product.icon && <i className={"fa-2xl fa-solid " + product.icon}></i>}
                                                </div>
                                            </div>
                                            <h3 className="fw-bolder fs-3">{product.title}</h3>
                                            <p className="my-3 fs-5 mb-4">{product.excerpt}</p>
                                            <div className="align-items-end mb-4 mt-auto">
                                                {product.hasPage && <a className={"me-2 btn btn-beam-white-" + beamIndex} href={"/products/" + product.title}>Details</a>}
                                                {product.hasBrochure && <a className={"btn btn-beam-whiteline-" + beamIndex} href={require(`./../brochures/${product.title}.pdf`)} target="_blank" rel="noreferrer" >Download PDF</a>}
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            })
                        }
                    </Fragment>
                )}
            </section>

            <section className="row benefit">
                <div className="col-12 offset-lg-1 col-lg-5 beam-text-box benefit-box">
                    <h2><b>Benefits</b></h2>
                    <p>{dataProducts.benefits.excerpt}</p>
                    <div>
                        <Link className="btn btn-beam my-2 w-100 btn-primary" to="/contact">Contact us</Link>
                        <Link className="btn btn-beam my-2 w-100 btn-outline-success" to={{ pathname: "/contact", state: { isRequestLiveDemo: true } }}>Request live demo</Link>
                    </div>
                </div>
                <div className="col-12 offset-lg-1 col-lg-7 beam-text-box benefit-list">
                    {dataProducts.benefits.description.map((item, itemIndex) => (
                        <p key={itemIndex}>
                            <span className={`dot bg-beam-${(itemIndex % 7) + 1}`}></span>
                            {item}
                        </p>
                    ))}
                </div>
            </section>
        </div>
    )
}

export default PageProducts




