const dataHeader = [
    {
        title: "Industries", href: "/industries", isExternal: false,
        submenu: [
            { path: "industries", color: "red", title: "Public Safety", hash: "public-safety", },
            { path: "industries", color: "yellow", title: "Private Security", hash: "private-security", },
            { path: "industries", color: "blue", title: "Unified Communication", hash: "communication", },
            { path: "industries", color: "purple", title: "Smart Transportation", hash: "transportation", },
            { path: "industries", color: "gray", title: "Merchandising", hash: "merchandising", },
            { path: "industries", color: "smoke", title: "Telehealth", hash: "telehealth", },
            { path: "industries", color: "green", title: "Logistics & Delivery", hash: "logistics", }]
    },
    { title: "Products", href: "/products", isExternal: false },
    { title: "Partners", href: "/partners", isExternal: false },
    { title: "Developers", href: "/developers", isExternal: false },
    { title: "About", href: "/about", isExternal: false },
    // { title: "Contact us", href: "mailto:first@beam.live?subject=Contact us", isExternal: true },
    { title: "Contact us", href: "/contact", isExternal: false },
]

export default dataHeader