import React from 'react';
import { Helmet } from "react-helmet";

const PageTerms = () => (
    <div className="container">
        <Helmet>
            <title>beamLive Terms and Conditions - Mobile IoT Communication as a Service</title>
            <meta name="description" content="beamLive - Mobile IoT “Communication as a Service” configures IoT data into relevant content and mobile messaging apps using sub-second M2M connectivity and AI." />
        </Helmet>

        <h1>Beam Live Inc. Terms and Conditions</h1>
        <p>
            Beam Mobile IoT Communication Service is primarily an enterprise-grade cloud-based content sharing, messaging and collaborative tool that is customized for specific needs of an organization and its target Users.
            We believe communication industry must protect the end user’s content and information with ultimate privacy to create an environment of trust and transparency.
        </p>
        <p>
            In general Beam terms and conditions are defined for each organization. They provide their End User’s features and functions under their own service-level -agreement (SLA).
            However, BeamLive SLA generally offers that the Services are to be available to End User not less than a PERCENT (to be specified for each organization) of each day (measured by minutes in a day).
            Notwithstanding anything in BeamLive SLA to the contrary, End User understands, acknowledges and agrees that it will not be entitled to any Service Level Credit or Partial Service Level Credit in the event the applicable Material Problem or Non Material Problem, respectively, involves a Credit Excluded Event. Except as provided otherwise in the Agreement and/or subject to the terms of limitations or exceptions provided in the warranty provided to BeamLive by the manufacturer or distributor of any hardware provided to Customer as part of the Services, BeamLive will replace any defective or malfunctioning hardware.
        </p>
    </div>
)

export default PageTerms