import React from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import AnimBeamNetwork from '../component/animBeamNetwork';
import dataHome from './../data/dataHome';

const PageHome = () =>
    <div id="pageHome" className="container">
        <Helmet>
            <title>beamLive - Mobile IoT Communication as a Service</title>
            <meta name="description" content="beamLive - Mobile IoT “Communication as a Service” configures IoT data into relevant content and mobile messaging apps using sub-second M2M connectivity and AI." />
        </Helmet>

        <section className="row header">
            <div className="col-12 col-lg-5 order-last order-lg-first px-4 position-relative">
                <span className="blue-box"></span>
                <a className="logo d-block mb-3 text-decoration-none" href="/">
                    <img src="/images/beamLive-logo.svg" height="36" alt="beamLive logo" />
                    <div class="fw-bold text-black-50" style={{ fontSize: "7.1px" }}>Unified Communication of Things<br />as-a-Service on Cloud or Edge</div>
                </a>
                <h1 className="fs-1 fw-bolder"><b>{dataHome.header.title}</b></h1>
                <p className="fs-3">{dataHome.header.description}</p>
                <section className="col-12 buttons d-flex justify-content-start">
                    <Link className="btn btn-beam btn-lg btn-primary me-4" to="/about" >Learn more</Link>
                    <Link className="btn btn-beam btn-lg btn-outline-success" to={{ pathname: "/contact", state: { isRequestLiveDemo: true } }}>Request live demo</Link>
                </section>
            </div>
            <div className="col-12 col-lg-7">
                <AnimBeamNetwork />
            </div>
        </section>

        <section className="row description mb-5">
            <span className="exclamation d-none d-lg-block" />
            <span className="iot d-none d-lg-block" />
            <span className="analytics d-none d-lg-block" />
            <span className="blue-box"></span>
            <div className="col-12 beam-text-box bg-white top-box">
                {dataHome.description[0].map((item, index) => <p key={index} dangerouslySetInnerHTML={{ __html: item }}></p>)}
            </div>
            <div className="col-12 mt-md-5 beam-text-box bg-white bottom-box ">
                {dataHome.description[1].map((item, index) => <p key={index} dangerouslySetInnerHTML={{ __html: item }}></p>)}
            </div>
        </section>

        <section className="row my-5">
            <div className="col-12 my-5">&nbsp;</div>
        </section>

        <section className="row features my-5">
            <div className="col-4 col-lg-3 position-relative">
                <span className="icon-place" />
                <span className="icon-audio" />
                <span className="icon-exclamation" />
                <span className="icon-pay d-none d-lg-block" />
                <span className="icon-incident d-none d-lg-block" />
            </div>
            <div className="col-8 col-lg-3">
                <div className="keyword-box">
                    {dataHome.features.keywords.map((keyword, keywordIndex) =>
                        <p className={`fw-bolder mb-3 text-beam-${(keywordIndex % 7) + 1}`} key={keywordIndex}>{keyword}</p>
                    )}
                </div>
            </div>
            <div className="col-12 col-lg-6 position-relative mt-4">
                <span className="green-box"></span>
                <div className="text-box bg-white">
                    <h4>{dataHome.features.title}</h4>
                    <div className="ms-5 mt-4 fs-6">
                        {dataHome.features.description.map((feature, featureIndex) =>
                            <p key={featureIndex}>
                                <span className={`dot bg-beam-${(featureIndex % 7) + 1}`}></span>
                                {feature}
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </section>

        <section className="row my-5">
            <div className="col-12">&nbsp;</div>
        </section>

        <section className="row blog position-relative my-5">
            <h2 className="fw-bolder text-center"><b>{dataHome.blog.title}</b></h2>
            <span className="red-box"></span>
            <div id="carousel-box" className="carousel-box carousel slide py-3" data-bs-ride="carousel">
                <div className="carousel-inner py-5">
                    {dataHome.blog.news.map((news, newsIndex) => (
                        <div className={"row carousel-item " + ((newsIndex === 0) && "active")} data-bs-interval="10000" key={newsIndex}>
                            <div className="col-12 offset-lg-1 col-lg-5 px-3">
                                <div className="carousel-item-image">
                                    <img className="w-100" src={require("../images/" + news.image)} alt={news.alt} />
                                </div>
                            </div>
                            <div className="col-12 col-lg-5 px-3 d-flex align-items-stretch">
                                <div className="carousel-item-text text-end">
                                    <p className="fw-bold white-space-break-spaces">{news.description}</p>
                                    <Link className="btn btn-beam btn-primary mt-2" to={news.to} >Learn more</Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="carousel-indicators">
                    {dataHome.blog.news.map((news, newsIndex) => (
                        <button key={newsIndex}
                            className={(newsIndex === 0) ? "active" : null}
                            data-bs-target="#carousel-box"
                            data-bs-slide-to={newsIndex}
                            aria-label={news.alt}
                            aria-current={(newsIndex === 0) ? "true" : null}
                        ></button>
                    ))}
                </div>
                <button className="carousel-control-prev d-none d-lg-flex" data-bs-target="#carousel-box" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next d-none d-lg-flex" data-bs-target="#carousel-box" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </section>
    </div>

export default PageHome
