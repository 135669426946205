import React from 'react';
import { Helmet } from "react-helmet";
import { dataPartners } from './../data/dataPartners';
import { Link } from 'react-router-dom';
import AnimHandshake from './../component/animHandshake';

const PagePartners = () => {

    const showPartnerBox = (partnerIndex) =>
        <div className="partner-box" key={partnerIndex}>
            <img src={require("../images/" + dataPartners.partners[partnerIndex].image)} name={dataPartners.partners[partnerIndex].name} alt={dataPartners.partners[partnerIndex].name} />
            <p className="white-space-break-spaces">{dataPartners.partners[partnerIndex].note}</p>
        </div>

    const showPartnerCard = () =>
        <div className="partner-box d-inline-block text-start" >
            <h3 className="fw-bolder text-center">Investment Partner</h3>
            <img className="my-4" style={{ width: "100%" }} src={require("../images/partner-european.png")} name="Investment Partner" alt="Investment Partner" />
            <h5><b>Investment Funding for Development of the Real-time Mobile IOT Communication Solutions</b></h5>
            <div><b>Project name: </b>Development of the real-time IOT communication solution</div>
            <div><b>Recipient name: </b>BeamLive</div>
            <div><b>Project ID: </b>GINOP-8.1.3/8-17</div>
            <div>WE APPRECIATE THE SUPPORT OF THE HUNGARIAN GOVERNMENT AND THE EUROPEAN UNION.</div>
        </div>


    return <div id="pagePartners" className="container">
        <Helmet>
            <title>beamLive Partners- Mobile IoT Communication as a Service</title>
            <meta name="description" content="beamLive - Mobile IoT “Communication as a Service” configures IoT data into relevant content and mobile messaging apps using sub-second M2M connectivity and AI." />
        </Helmet>

        <section className="row header">
            <div className="col-12 col-lg-6 position-relative order-last order-lg-first">
                <span className="blue-box"></span>
                <div className="text-box">
                    <h1><b>{dataPartners.title}</b></h1>
                    <p>{dataPartners.description}</p>
                </div>
            </div>
            <div className="col-12 col-lg-6">
                <AnimHandshake />
            </div>
        </section>
        <section className="row boxes">
            <span className="red-box" />
            <span className="green-box" />
            <div className="col-12 col-lg-6 col-1">
                {showPartnerBox(0)}
                {showPartnerBox(2)}
                {showPartnerBox(4)}
            </div>
            <div className="col-12 col-lg-6 col-2">
                {showPartnerBox(1)}
                {showPartnerBox(3)}
                {showPartnerCard()}
            </div>
        </section>

        <section className="row contact">
            <span className="red-box"></span>
            <div className="col-12 offset-lg-3 col-lg-7 text-box">
                {dataPartners.contact.map((line, lineIndex) => (
                    <p key={lineIndex}>{line}</p>
                ))}
                <div className="row mt-5">
                    <div className="col">
                        <Link className="col btn btn-beam w-100 btn-primary" to="/contact">Contact us</Link>
                    </div>
                    <div className="col">
                        <Link className="col btn btn-beam w-100 btn-outline-success btn-bg-white" to={{ pathname: "/contact", state: { isRequestLiveDemo: true } }}>Request live demo</Link>
                    </div>
                </div>
            </div>
        </section>
    </div>
}

export default PagePartners