import React from 'react';
//import { gsap } from "gsap";

const AnimHandshake = ({ className }) => {
    const refCloud = React.useRef(null);

    // useEffect(() => {
    //     gsap.from(refCloud.current, {
    //         autoAlpha: 0,
    //         ease: 'none',
    //         delay: 0.9
    //     });
    // }, []);

    return (
        <span className={`anim-handshake  ${className ?? ""}`}>
            <span className="image-layer-clouds" ref={refCloud}></span>
            <span className="image-layer-lines"></span>
            <span className="image-layer-hands"></span>
        </span>
    )
}
export default AnimHandshake