import React from 'react';
import { gsap } from "gsap";

const AnimBeamNetwork = ({ className }) => {
    React.useEffect(() => {
        gsap.set(".anim-beam-network", { opacity: 1 });
        gsap.from(".anim-beam-network", { opacity: 0, duration: 1 });
        gsap.to(".anim-beam-network .icon-clouds", { repeat: -1, yoyo: true, duration: 4, scale: 1.1, }, 0)
        gsap.to(".anim-beam-network .icon-lines", { repeat: -1, yoyo: true, duration: 4.5, x: 40, }, 0)
        gsap.to(".anim-beam-network .icon-ring", { repeat: -1, yoyo: true, duration: 5, x: 20, scale: 1.1, rotate: -5 }, 0)
    }, []);

    return (
        <div className={`anim-beam-network ${className ?? ""}`}>
            <span className="icon-clouds"></span>
            <span className="icon-lines"></span>
            <span className="icon-ring"></span>
        </div>)
}
export default AnimBeamNetwork