import React, { Fragment } from 'react';
import { Link, Outlet, useLocation } from "react-router-dom";
import { animateScroll as scroll } from 'react-scroll'
import dataHeader from '../data/dataHeader';

const PageLayout = () => {
    let { hash, pathname } = useLocation();

    React.useEffect(() => {
        // close Navigation after loading each page
        document.getElementById('beam-nav').classList.remove("show");
        document.querySelectorAll('button.navbar-toggler')[0].classList.add("collapsed");

        // scrollTo in each new page
        hash
            ? document?.getElementById(hash.substr(1))?.scrollIntoView({ behavior: "smooth" })
            : window.scrollTo(0, 0)
    }, [hash, pathname])

    return (
        <Fragment>
            <header>
                <nav className="navbar navbar-expand-lg">
                    <div className="container-fluid">
                        {(pathname !== "/") && <a className="navbar-brand m-4" href="/" >
                            <img src="/images/beamLive-logo.svg" height="52" alt="beamLive logo" />
                        </a>}
                        <button className="navbar-toggler collapsed"
                            data-bs-toggle="collapse" data-bs-target="#beam-nav"
                            aria-controls="beam-nav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="navbar-collapse collapse m-3" id="beam-nav">
                            <ul className="navbar-nav ms-auto flex-nowrap">
                                {dataHeader.map((item, itemInedx) => {
                                    const linkProps = {
                                        className: "nav-link " + (item?.submenu ? "dropdown-toggle" : ""),
                                        to: item.href,
                                        href: item.isExternal ? item.href : undefined,
                                        target: item.isExternal ? "_blank" : undefined,
                                        rel: item.isExternal ? "noreferrer" : undefined,
                                        role: (item?.submenu ?? false) ? "button" : undefined,
                                        "data-bs-toggle": (item?.submenu ?? false) ? "dropdown" : undefined,
                                        "aria-expanded": (item?.submenu ?? false) ? "false" : undefined,
                                        id: (item?.submenu ?? false) ? ("navbar" + itemInedx) : undefined,
                                    }
                                    return (
                                        <li className={"nav-item " + (item?.submenu ? "dropdown" : "")} key={itemInedx}>
                                            {(item.isExternal)
                                                ? <a {...linkProps}>{item.title}</a>
                                                : <Link {...linkProps}>{item.title}</Link>
                                            }
                                            {(item?.submenu ?? false) &&
                                                <ul className="dropdown-menu" aria-labelledby={item?.submenu && ("navbar" + itemInedx)}>
                                                    {item?.submenu?.map((subItem, subItemIndex) => (
                                                        <li className="dropdown-item d-flex align-items-center" key={subItemIndex}>
                                                            <span className={`bg-beam-${subItem.color}`}></span>
                                                            <Link to={`/${subItem.path}#${subItem.hash}`} className="dropdown-item">{subItem.title}</Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            }
                                        </li>
                                    )
                                })}
                            </ul>
                            <ul className="navbar-nav-right d-flex list-unstyled m-0 ms-lg-4 align-items-center">
                                <li>
                                    <div className="wrap-drop" id="beam_lang_block">
                                        <span>EN</span>
                                        <ul className="drop">
                                            <li className="selected"><a className="nav-link" href="#">EN</a></li>
                                            <li><a className="nav-link" href="#">KR</a></li>
                                        </ul>
                                    </div>
                                </li>
                                {/* <li className="nav-item"><a className="nav-link" href="#">Blog</a></li> */}
                                <li className="nav-item">
                                    <a className="nav-link" href="https://first-admin.beam.live/" target="_blank" rel="noreferrer">Sign in</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* <!-- navbar-collapse.// --> */}
                </nav>
            </header>

            <Outlet />

            <footer className="bean_cus_footer">
                <div className="container">
                    <button className="move-to-top" href="#" onClick={() => scroll.scrollToTop()} aria-label="scroll to top" />
                    <div className="d-flex flex-column flex-lg-row justify-content-between align-items-center text-center">
                        <ul className="footer-menu list-unstyled d-flex flex-column flex-lg-row">
                            <li>
                                <Link className="nav-link" to="/privacy">Privacy Policy</Link>
                            </li>
                            <li>
                                <Link className="nav-link" to="/terms">Terms and Conditions</Link>
                            </li>
                        </ul>
                        <ul className="footer-copyright list-unstyled order-last order-lg-first text-center text-leg-start">
                            <li>
                                <small>2023 - beam.live all rights are reserved</small>
                                <small className="hide d-none d-lg-inline-block ms-2"> version {require('../../package.json').version}</small>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
        </Fragment>
    )
}

export default PageLayout