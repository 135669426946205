export const dataProducts = {
    categories: [
        {
            title: "Mobile Apps",
            products: [
                {
                    title: "beamLocate",
                    excerpt: "Turn your smartphone into a sub-second latency GPS monitoring service with embedded alerts and analytics.",
                    icon: "fa-location-dot",
                    hasPage: false,
                    hasBrochure: false,
                },
                {
                    title: "beam2Talk",
                    excerpt: "Enhance your push-to-talk Audio experience over LTE/WiFi with Video, GPS, and unlimited channels",
                    icon: "fa-mobile-screen-button",
                    hasPage: false,
                    hasBrochure: true,
                },
                {
                    title: "beamAssist",
                    excerpt: "A 3-button app that can be configured to INSTANTLY report user status and communicate with live GPS and Audio & Video",
                    icon: "fa-wheelchair",
                    hasPage: false,
                    hasBrochure: true,
                },
                {
                    title: "beamAlert",
                    excerpt: "A mass alert and notification system that allows 2-way communicaton using Text, Live Video & Location with 3-button response back",
                    icon: "fa-bell",
                    hasPage: false,
                    hasBrochure: true,
                },
                {
                    title: "beamOnMove",
                    excerpt: "Built for mission-critical dispatch & response. It embeds beamLocate, beam2talk & beamAssist. Tags events and actions with location for advanced Analytics.",
                    icon: "fa-triangle-exclamation",
                    hasPage: false,
                    hasBrochure: false,
                },
                {
                    title: "beamCAD",
                    excerpt: "Configure an intuitive Dispatch service that uses AI to match an Event with available Mobile & IoT Resources. It complements beamOnMove.",
                    icon: "fa-microchip",
                    hasPage: false,
                    hasBrochure: false,
                },

            ],
        },
        {
            title: "Cloud Services",
            products: [
                {
                    title: "beamFirst",
                    excerpt: "End-to-end mission-critical communication system designed by and for first responders. Enables users to chat, collaborate, and share full spectrum information with team members, dispatchers, and all levels of the command organization with sub-second latency. Device agnostic - integrates all smartphones, IoT, and wearable devices.",
                    icon: "fa-circle",
                    hasPage: true,
                    hasBrochure: true,
                    descriptions: [
                        "Smooth migration from Legacy to Cost-effective Broadband Communication.",
                        "The beamFirst suite of products is designed by and for first responders and offers a smooth migration from legacy communication to cost-effective broadband technology.",
                        "It is a readily customizable, cloud-based solution that leverages the proliferation of mobile communication and the ubiquitous Internet of Things (IoT).",
                        "Beam empowers first responders with heightened communication capabilities, including text, audio/video and sub-second GPS connectivity among smart and wearable devices. Users can share secured, profiled, geotagged IoT content and media, as well as real-time collaboration with citizens and dispatchers.",
                        "Beam converts real-time IoT data - including audio/video and GPS - into actionable knowledge that augments human intelligence.",
                        "It enhances mission-critical mobile communication for organizations that are dependent on time-centric IoT connectivity and decision-making.  This end-to-end service is designed by law enforcement experts for Emergency Response, NG911/PSAPs and First Responder safety.",
                    ],
                    benefitTitle: "Integrates communication, content sharing, notifications, mobile messaging and IoT connectivity including GPS.",
                    benefits: [
                        "One, easy-to-use platform that removes multi-vendor layers to drastically reduces costs;",
                        "Smooth migration from legacy, voice/text-driven communication to broadband IoT and mobile technology;",
                        "It is cloud-based and does not require additional infrastructure or storage hardware;",
                        "Supports BYOD and works on iOS, Android and Wins mobile and desktop devices;",
                        "Easily configurable to specific client needs with minimal cost and effort; ",
                        "Adds employee safety features that can be tied to external or internal dispatch services;",
                        "Intuitive User Interface offers easy training for employees and administrators;",
                        "Connects mobile workforce with stationary workers and customers using chat and GPS;",
                        "Secure and scalable solution that parallels the growth of mobile workforce;",
                        "Connects to any legacy enterprise email/messaging services;",
                        "Improves enterprise-wide decision-making, analytics and alignment.",
                    ]
                },
                {
                    title: "beamRide",
                    excerpt: "A soft GPS meter that is certified by NTEP and NCWM. beamRide integrates all meter functionality, dispatching, and payment options. Provides the next level of safety for both drivers and passengers.",
                    icon: "fa-circle",
                    hasPage: false,
                    hasBrochure: true,
                },
                {
                    title: "beamRx",
                    excerpt: "Overlays live video and audio with medical sensor data using beam sub-second M2M connectivity to offer a false virtual office visit. Vital and life-saving alerts are continually generated using Artificial Intelligence (AI).",
                    icon: "fa-circle",
                    hasPage: false,
                    hasBrochure: false,
                },
                {
                    title: "beamLogistics",
                    excerpt: "End-to-end mobile cloud technology that covers warehouse activities, mobile resources and links end-customers to delivery on-the-go. Eliminates costs associated with multi-vendor services. ",
                    icon: "fa-box",
                    hasPage: false,
                    hasBrochure: false,
                },
                {
                    title: "beamStore",
                    excerpt: "Augments intelligence collected from mobile devices with live IoT data gathered from retail stores. Information is converted into real-time actionable knowledge which saves money and improves operations.  ",
                    icon: "fa-basket-shopping",
                    hasPage: false,
                    hasBrochure: false,
                },
                {
                    title: "beamContact",
                    excerpt: "A configurable call center that is custom-designed to each business organization. Integrates with Beam’s communication services, reduces layers of decision-making with extensive real-time and historical analytics, and streamlines operations",
                    icon: "fa-headset",
                    hasPage: false,
                    hasBrochure: false,
                },
            ],
        },
        {
            title: "Analytics & AI",
            products: [
                {
                    title: "beamAnalytics: ",
                    excerpt: "Combine human intelligence and IoT data to create Actionable Knowledge in real-time with indexed historical Analytics",
                    icon: "fa-chart-area",
                    hasPage: false,
                    hasBrochure: false,
                },
                {
                    title: "beamVision: ",
                    excerpt: "Turn your camera into an intelligent eye, detect abnormalities and objects of interests. Instantly alert and collaborate. ",
                    icon: "fa-video",
                    hasPage: false,
                    hasBrochure: false,
                },
                {
                    title: "beamEdge",
                    excerpt: "Build your own cloud on a mini-PC. Use any of beamApps for end-to-end encrypted text, GPS, Voice and Video messaging.",
                    icon: "fa-server",
                    hasPage: false,
                    hasBrochure: false,
                },
            ],
        },
        {
            title: "IoT & Wearables",
            products: [
                {
                    title: "beamWear",
                    excerpt: "A customizable, wearable technology for First Responders that combines live GPS, body Camera, Biometrics with SOS and push-to-talk.",
                    icon: null,
                    iconSVG: "<svg fill='currentColor' width='19' height='34' viewBox='0 0 19 34' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M4 4C4 2.11438 4 1.17157 4.58579 0.585786C5.17157 0 6.11438 0 8 0C9.88562 0 10.8284 0 11.4142 0.585786C12 1.17157 12 2.11438 12 4V7H4V4ZM4.02518 16.4379H0C0.00288868 13.0384 0.0595614 11.2834 1.17142 10.1716C2.34299 9 4.22861 9 7.99984 9C11.7711 9 13.6567 9 14.8283 10.1716C15.8914 11.2347 15.9898 12.8857 15.9989 16H11.2319C10.8064 16 10.4276 16.2692 10.2876 16.6709L9.55322 18.7782L7.87362 11.3783C7.77577 10.9471 7.40647 10.6314 6.9654 10.6018C6.52432 10.5722 6.11614 10.8358 5.96156 11.2499L4.02518 16.4379ZM11.9424 18H15.9989C15.9898 21.1143 15.8914 22.7653 14.8283 23.8284C13.6567 25 11.7711 25 7.99984 25C4.22861 25 2.34299 25 1.17142 23.8284C0.159284 22.8163 0.021543 21.2712 0.00279775 18.4379H4.71932C5.13673 18.4379 5.51023 18.1786 5.65619 17.7875L6.6644 15.0864L8.37473 22.6217C8.47348 23.0568 8.84846 23.3738 9.29389 23.3988C9.73931 23.4238 10.1474 23.1508 10.2942 22.7295L11.9424 18ZM18 14C17.4477 14 17 14.4477 17 15V19C17 19.5523 17.4477 20 18 20C18.5523 20 19 19.5523 19 19V15C19 14.4477 18.5523 14 18 14ZM12 30V27H4V30C4 31.8856 4 32.8284 4.58579 33.4142C5.17157 34 6.11438 34 8 34C9.88562 34 10.8284 34 11.4142 33.4142C12 32.8284 12 31.8856 12 30Z'/></svg>",
                    hasPage: false,
                    hasBrochure: true,
                },
                {
                    title: "beamMeter",
                    excerpt: "The first NIST-approved, nationwide Soft GPS meter that can easily be configured via cloud with 99% accuracy.",
                    icon: "fa-gauge",
                    hasPage: false,
                    hasBrochure: false,
                },
                {
                    title: "beamOmni",
                    excerpt: "Unlimited Voice, SMS & Data on any Beam Mobile app.",
                    icon: "fa-cloud",
                    hasPage: false,
                    hasBrochure: false,
                },
            ],
        },
    ],

    benefits: {
        excerpt: "Integrates communication, content sharing, notifications, mobile messaging and IoT connectivity including GPS into:",
        description: [
            "One, easy-to-use platform that removes multi-vendor layers to drastically reduces costs;",
            "Smooth migration from legacy, voice/text-driven communication to broadband IoT and mobile technology;",
            "It is cloud-based and does not require additional infrastructure or storage hardware;",
            "Supports BYOD and works on iOS, Android and Wins mobile and desktop devices;",
            "Easily configurable to specific client needs with minimal cost and effort; ",
            "Adds employee safety features that can be tied to external or internal dispatch services;",
            "Intuitive User Interface offers easy training for employees and administrators;",
            "Connects mobile workforce with stationary workers and customers using chat and GPS;",
            "Secure and scalable solution that parallels the growth of mobile workforce;",
            "Connects to any legacy enterprise email/messaging services;",
            "Improves enterprise-wide decision-making, analytics and alignment.",
        ]
    }
}


