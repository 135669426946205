const dataHome = {
    header: {
        title: "UPGRADE TO BEAM",
        description: "From legacy text/audio/video services to Mobile IoT Communication",
    },
    description: [
        [
            "We offer a seamless transition from legacy Voice, Text, Video Communication to <b>Broadband Mission Critical Mobile IoT Communication</b> using Smart and Wearable Devices, enabled by Beam’s Analytics-in-Real-time.",
            "Before you decide on the future of your mobile communication and resource/fleet management <a class='fs-5' href='/contact' target='_self'>Contact us</a>",
            "Let us match Beam's <b>“Connected Intelligence”</b> with your core business offering and show you how to decrease your operating and capital cost significantly, while often maintaining your existing infrastructure, using Beam's Analytics-in-Real-time.",
            "Beam currently services several different markets: Public Safety, Smart Transportation, Logistics and Delivery, Merchandising, Healthcare.",
        ],
        [
            "Configure IoT data into mobile messaging apps using sub-second M2M connectivity and AI.",
            "Share relevant content from various IoT sources for mission critical and time sensitive communication.",
            "Easy to use and configure on Android, iOS and Wins devices.",
        ],
    ],
    features: {
        title: "Beam’s Unified Communication of Things-as-a-Service (UCTaaS) on Cloud or Edge offers the ability to:",
        description: [
            "Manage mobile workforces, processes and assets with our intuitive, easy to use portal. Configure mobile and web services and provide custom analytics;",
            "Chat with text, video, GPS and other custom features designed for instant connectivity of workforce and responders with decision-makers and clients. ",
            "Share geotagged media and IoT content with targeted audiences accomplished through using AI-driven indexing and Beam’s patented sub-second M2M connectivity.",
            "Collaborate by capturing Event attributes, AI-profiling of Tasks and creating real-time Actionable Knowledge extracted from human intelligence and IoT data.",
            "Consolidate multiple, costly services within your organization. Analyze real-time content from humans and machines and turn your core business into results-driven operations.",
        ],
        keywords: [
            "Manage",
            "Chat",
            "Share",
            "Collaborate",
            "Analyze",
        ],
    },
    blog: {
        title: "beamNews",
        news: [
            {
                image: "article-intel.png",
                alt: "beam named Intel® IoT Market Ready Solution Gold-level Partner.",
                description: `beam named Intel® IoT Market Ready Solution 
                Gold-level Partner.`,
                to: "/partners",
            },
            {
                image: "article-3.svg",
                alt: "beamFirst Mobile IoT Communication App",
                description: "beamFirst Mobile IoT Communication App now FirstNet Verified and available in AT&T FirstNet app catalog",
                to: "/industries#public-safety",
            },
            {
                image: "article-1.svg",
                alt: "beam Public Safety",
                description: "beam named authorized Verizon 5G Public Safety Practice partner",
                to: "/industries#public-safety",
            },
            {
                image: "article-4.svg",
                alt: "beam GPS-based Taxi Meter",
                description: "beam launches the only Nationally Certified GPS-based Taxi Meter",
                to: "/industries#transportation",
            },
        ]
    },
}

export default dataHome