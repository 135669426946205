import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AnimCloudPoint from './../component/animCloudPoint';

const Page404 = () => {
    const navigate = useNavigate();
    const location = useLocation();
    location.state = 400
    console.log(location, navigate);


    return (
        <div id="page404" className="container">
            <AnimCloudPoint className="d-block d-lg-none" />
            <Helmet>
                <title>beamLive - Mobile IoT Communication as a Service</title>
                <meta name="description" content="beamLive - Mobile IoT “Communication as a Service” configures IoT data into relevant content and mobile messaging apps using sub-second M2M connectivity and AI." />
            </Helmet>
            <section className="row form">
                <div className="col-1 col-lg-4 icons">
                    <span className="icon-cloud1"></span>
                    <span className="icon-home"></span>
                </div>
                <div className="col-10 col-lg-4">
                    <div className="head-box mb-4">
                        <h1><b>404 NOT FOUND</b></h1>
                        <div>We can't seem to find the thing you were looking for.</div>
                        <div>If you typed the address, double-check the spelling.</div>
                    </div>
                    <div>
                        <div className="text-center">
                            <a className="btn btn-beam btn-secondary me-2" target="_self" onClick={() => navigate(-1)}>Go Back</a>
                            <a className="btn btn-beam btn-primary ms-2" target="_self" href="https://beam.live/">Visit beamLive</a>
                        </div>
                    </div>
                </div>
                <div className="col-1 col-lg-4 icons">
                    <span className="icon-cloud2"></span>
                    <span className="icon-email"></span>
                </div>
                <section className="row my-5"></section>
                <section className="row my-5"></section>
                <section className="row my-5"></section>
            </section>
        </div>
    );
}

export default Page404