import React from 'react';
import { gsap } from "gsap";

const AnimCloudPoint = ({ className }) => {
    React.useEffect(() => {
        gsap.set(".anim-cloud-point span", { opacity: 0 });
        gsap.to(".anim-cloud-point span", { opacity: 1, duration: 2 });
    }, []);

    return (
        <div className={`anim-cloud-point ${className ?? ""}`}>
            <span className="icon-clouds"></span>
            <span className="icon-lines"></span>
            <span className="icon-place"></span>
        </div>)
}
export default AnimCloudPoint