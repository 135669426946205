import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Axios from 'axios';
import AnimCloudPoint from './../component/animCloudPoint';

const PageContact = ({ location }) => {
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const [message, setMessage] = useState();
    const [error, setError] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    // useEffect(() => sendFeedback, [])

    const handleSubmit = async () => {
        setIsSubmitting(true);
        try {
            validate()
            const url = (new URL('/v2/contact', process.env.REACT_APP_API_URL)).toString();
            const payload = { name, email, phone, message };
            console.log("Contact payload", payload)
            await Axios.post(url, payload)
                .then(res => {
                    console.log("Contact response", res.data);
                    alert("Successfully sent!");
                    window.location.reload();
                })
        } catch (ex) {
            console.error('Contact failed', ex.message);
            setError(ex.message);
        } finally {
            setIsSubmitting(false);
        }
    }

    const validate = () => {
        if (!name) { throw new Error('Please enter your name'); }
        if (!email) { throw new Error('Please enter your email address'); }
        // if (!phone) { throw new Error('Please enter your phone number'); }
        if (!message) { throw new Error('Please enter your message'); }
    }

    return (
        <div id="pageContact" className="container">
            <AnimCloudPoint className="d-block d-lg-none" />
            <Helmet>
                <title>beamLive Contact us - Mobile IoT Communication as a Service</title>
                <meta name="description" content="beamLive - Mobile IoT “Communication as a Service” configures IoT data into relevant content and mobile messaging apps using sub-second M2M connectivity and AI." />
            </Helmet>
            <section className="row form">
                <div className="col-1 col-lg-4 icons">
                    <span className="icon-cloud1"></span>
                    <span className="icon-home"></span>
                    <span className="icon-incident"></span>
                    <span className="icon-audio"></span>
                </div>
                <div className="col-10 col-lg-4">
                    <div className="head-box">
                        <h1><b>Contact beam</b></h1>
                        <p>If you have any question or request<br />please contact us</p>
                    </div>
                    <div>
                        <div className="form-group">
                            <input className="form-control" type="text" id="name" name="name" placeholder="Name" onChange={e => setName(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <input className="form-control" type="email" id="email" name="email" placeholder="E-mail address" onChange={e => setEmail(e.target.value)} />
                        </div>
                        <div className="form-group optinal">
                            <input className="form-control" type="tel" id="phone" name="phone" placeholder="Phone number" onChange={e => setPhone(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <textarea className="form-control" type="text" id="message" name="message" placeholder="Your message..." rows="6" onChange={e => setMessage(e.target.value)}
                                defaultValue={location?.state?.isRequestLiveDemo && "I like to signup for a live demo"} />
                        </div>
                        {isSubmitting && (<p>Submitting. Please wait...</p>)}
                        {error && (<p className="text-danger">{error}</p>)}
                        <div className="text-center">
                            <button className="btn btn-beam btn-primary w-50" type="submit" onClick={handleSubmit}>Submit</button>
                        </div>
                    </div>
                </div>
                <div className="col-1 col-lg-4 icons">
                    <span className="icon-cloud2"></span>
                    <span className="icon-email"></span>
                    <span className="icon-exclamation"></span>
                </div>
            </section>

            <section className="row address">
                <span className="blue-box d-none d-lg-block"></span>
                <div className="col-12 offset-lg-2 col-lg-4">
                    <div className="text-box">
                        <h3>beamLive Corporate Headquarters:</h3>
                        <p>2995 Woodside Road, Suite 62049<br />Woodside, CA 94062</p>
                        <p></p>
                        <h3>London Office:</h3>
                        <p>28 Penton Street<br />London, N1 9PS UK</p>
                    </div>
                </div>
                <div className="col-12 offset-lg-1 col-lg-5">
                    <AnimCloudPoint />
                </div>
            </section>
        </div>
    );
}

export default PageContact;