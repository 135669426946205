import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";
import dataAbout from '../data/dataAbout'
import { Link } from 'react-router-dom';
import AnimBeamNetwork from './../component/animBeamNetwork';

const PageAbout = () =>
    <div id="pageAbout" className="container">
        <Helmet>
            <title>beamLive About Us - Mobile IoT Communication as a Service</title>
            <meta name="description" content="beamLive - Mobile IoT “Communication as a Service” augments human intelligence and mobile content with live IoT data and turn it into Actionable Knowledge." />
        </Helmet>

        <section className="row header">
            <div className="col-12 col-lg-6 order-last order-lg-first">
                <div className="text-box">
                    <span className="orange-box"></span>
                    <h1><b>{dataAbout.header.title}</b></h1>
                    <p>{dataAbout.header.description[0]}</p>
                    <p>{dataAbout.header.description[1]}</p>
                </div>
            </div>
            <div className="col-12 col-lg-6">
                <AnimBeamNetwork />
            </div>
        </section>

        <section className="row description">
            <span className="green-box"></span>
            <div className="text-box bg-white">
                <h2><b>{dataAbout.patent.title}</b></h2>
                {dataAbout.patent.description.map((item, index) => <p key={index}>{item}</p>)}
            </div>
            <span className="icon-place d-none d-lg-block" />
            <span className="icon-exclamation" />
            <span className="icon-iot d-none d-lg-block" />
        </section>

        <section className="row features">
            <span className="red-box"></span>
            <span className="icon-analytics" />
            <span className="icon-incident d-none d-lg-block" />
            <div className="text-box bg-white">
                <h2><b>{dataAbout.features.title}</b></h2>
                {dataAbout.features.description.map((feature, featureIndex) => (
                    <p key={featureIndex}>
                        <span className={`dot bg-beam-${(featureIndex % 7) + 1}`}></span>
                        {feature}
                    </p>
                ))}
            </div>
        </section>

        <section className="row boxes">
            {dataAbout.boxes.map((box, boxIndex) => (
                <Fragment key={boxIndex}>
                    <div className={`col-12 col-lg-${12 / dataAbout.boxes.length}`}>
                        <div className={`box-${box.id} box-icon ${(boxIndex % 2 === 1) ? "float-end float-lg-none" : ""}`}>
                            <span></span>
                            <h3>{box.title}</h3>
                            <p>{box.description}</p>
                            {((dataAbout.boxes.length - 1) > boxIndex) && <i className="arrow d-none d-lg-block"></i>}
                        </div>
                    </div>
                    <div className="col-12 d-block d-lg-none">
                        {((dataAbout.boxes.length - 1) > boxIndex) && <i className="arrow"></i>}
                    </div>
                </Fragment>
            ))}
        </section>

        <section className="row different">
            <h2><b>{dataAbout.different.title}</b></h2>
            <span className="blue-box"></span>
            <div className="bg-white text-box text-box-description">
                {dataAbout.different.description}
            </div>
            <div className="bg-white text-box text-box-items">
                {dataAbout.different.items.map((item, itemIndex) => (
                    <p key={itemIndex}>
                        <span className={`dot bg-beam-${(itemIndex % 7) + 1}`}></span>
                        {item}
                    </p>
                ))}
            </div>
        </section>

        <section className="row buttons d-flex justify-content-center">
            <Link className="btn btn-beam btn-lg btn-primary" to="/contact" >Contact us</Link>
            <Link className="btn btn-beam btn-lg btn-outline-success" to={{ pathname: "/contact", state: { isRequestLiveDemo: true } }}>Request live demo</Link>
        </section>
    </div>


export default PageAbout




