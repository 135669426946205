import React from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { dataProducts } from './../data/dataProducts';

const PageProduct = () => {
    const navigate = useNavigate()
    const slug = useParams().slug?.toLowerCase()
    const [dataProduct, setDataProduct] = React.useState(null)

    const findProduct = () => {
        for (const category of dataProducts.categories) {
            const selectedProduct = category.products.find(product => product.title.toLowerCase() === slug)
            if (selectedProduct) return selectedProduct
        }
        return null
    }

    // Redirect to 404
    React.useEffect(() => {
        if (!slug) navigate("/products");
        const selectedProduct = findProduct()
        if (!selectedProduct) navigate("/products");
        setDataProduct(selectedProduct)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return dataProduct &&
        <div id="pageProduct" className="container">
            <Helmet>
                <title>beamLive Products - Mobile IoT Communication as a Service</title>
                <meta name="description" content="beamLive - Mobile IoT “Caas” turns IoT data from different sources such as GPS, Camera, Home, Vehicle, Wearable & Medical devices into Android, iOS & Wins apps." />
            </Helmet>

            <section className="row header">
                <div className="col-12 bg-white hero-box ">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <h1><b>{dataProduct.title}</b></h1>
                            <p>{dataProduct.descriptions[0]}</p>
                            <p>{dataProduct.descriptions[1]}</p>
                            <section className="row buttons d-flex justify-content-center justify-content-lg-start">
                                <Link className="btn btn-beam btn-primary" to="/contact">Contact us</Link>
                                <Link className="btn btn-beam btn-outline-success btn-bg-white" to={{ pathname: "/contact", state: { isRequestLiveDemo: true } }}>Request live demo</Link>
                            </section>
                        </div>
                        <div className="col-12 col-lg-6">
                            <img src={require("../images/product-ucc-dashboard.png")} alt={dataProduct.title}></img>
                        </div>
                    </div>
                </div>
            </section>

            <section className="row icons">
                <span className="exclamation d-none d-lg-block" />
                <span className="pay d-none d-lg-block" />
                <span className="incident d-none d-lg-block" />
                <div className="col-12 col-lg-6">
                    <span className="red-box"></span>
                    <div className="beam-text-box bg-white top-box">
                        <p>{dataProduct.descriptions[2]}</p>
                        <div>{dataProduct.descriptions[3]}</div>
                    </div>
                </div>
                <div className="col-12 col-lg-6 beam-text-box bg-white bottom-box ">
                    <span className="green-box"></span>
                    <p>{dataProduct.descriptions[4]}</p>
                    <div>{dataProduct.descriptions[5]}</div>
                </div>
                <div className="col-12 two-icons">
                    <span className="audio" />
                    <span className="place" />
                </div>
            </section>

            <section className="row benefit">
                <div className="col-12 col-lg-5 beam-text-box benefit-box">
                    <h2><b>Benefits</b></h2>
                    <p>{dataProduct.benefitTitle}</p>
                    <div>
                        <Link className="btn btn-beam my-2 w-100 btn-primary" to="/contact">Contact us</Link>
                        <Link className="btn btn-beam my-2 w-100 btn-outline-success" to={{ pathname: "/contact", state: { isRequestLiveDemo: true } }}>Request live demo</Link>
                    </div>
                </div>
                <div className="col-12 col-lg-7 beam-text-box benefit-list">
                    {dataProduct.benefits.map((item, itemIndex) => (
                        <p key={itemIndex}>
                            <span className={`dot bg-beam-${(itemIndex % 7) + 1}`}></span>
                            {item}
                        </p>
                    ))}
                </div>
            </section>
        </div>
}

export default PageProduct
