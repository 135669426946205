export const dataPartners = {
    title: "Partners",
    description: "beam continues to expand our Partner Network, and is currently engaged with these industry leading organizations.",
    partners: [
        {
            name: "intel", image: "partner-intel.png", note: `beam has been named as an Intel® IoT Market Ready Solution Partner opening the door for strategic and technical collaboration between the two companies to develop IoT solutions for key industries.
beam is planning to create a "beamStore Powered by Intel." This would facilitate greater access of beam’s "Mobile IoT Communication" on Intel mini PCs utilizing Intel Edge technology for developers globally to create their own IoT Messaging and Communication services in days versus months.
As an international industry leader, Intel (Nasdaq: INTC) is creating world-changing technology that enables global progress and enriches lives. Inspired by Moore’s Law, we continuously work to advance the design and manufacturing of semiconductors to help address our customers’ greatest challenges. By embedding intelligence in the cloud, network, edge and every kind of computing device, we unleash the potential of data to transform business and society for the better.` },
        { name: "firstnet", image: "partner-firstnet.png", note: "The beamFirst app is designated as AT&T FirstNet Verified in the FirstNet app catalog, making our Mobile IoT Communication solutions available to the entire FirstNet community." },
        { name: "verizon", image: "partner-verizon.svg", note: "beam is an authorized Verizon 5G Public Safety Practice Partner. Verizon 5G Labs are exploring the boundaries of 5G network technology, co-create new applications and hardware, and rethinking what’s possible in a 5G world." },
        { name: "synnex", image: "partner-synnex.png", note: "TD Synnex (the result of the merger between Synnex Corp. and Tech Data) is one of the largest value-added resellers of IT solutions in the world, with 22,000 employees located in over 100 countries. TD Synnex represents beam in the Public Safety industry space, offering beam’s innovative Mobile IoT Communication solutions for emergency response and public safety organizations, focusing on first responder communication and safety. beam also provides IoT medical, logistics and smart transportation solutions through the TD Synnex channel." },
        // { name: "seecode", image: "partner-seecode.png", note: "SEECODE COMPANY LIMITED is the world’s first developer and exporter of wireless PTT handheld microphone speakers, PTT headsets, and PTT buttons based on Bluetooth technology. Seecode and beam are partnering to deliver beam’s innovative Mobile IoT Communication on a variety of Seecode devices to an international audience." },
        { name: "axis", image: "partner-axis.svg", note: "Beam’s partnership with Axis Communications provides the Company with access to a range of innovative security and video surveillance technologies. Ranging from video cameras to wearables and security/surveillance devices and software, Axis technology and expertise can help enable Beam’s IoT public sector and security solutions. Currently we are utilizing Axis surveillance cameras in conjunction with Beam AI to develop a dispatch/response solution that detects objects of interest and abnormalities and prepares a profiled response in one minute or less." },
    ],
    contact: [
        "If you are working with one of our partners, we invite you to work through them to contact us regarding implementing a customized solution specific to your needs based on our tested and proven IoT communication platform. ",
        "If you are interested in becoming a beam sales or development partner for future business development, please contact...",
    ]

}